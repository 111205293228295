import React from 'react';

const Dashboard = () => {
    return(
        <div>
            <h2>Dashboard Ini kosong</h2>
            <h2>Tidak tau mau diisi apa:v</h2>
        </div>
    );
};

export default Dashboard;
